<template>
  <a-form
    :label-col="formLayout.labelCol"
    :wrapper-col="formLayout.wrapperCol"
    v-bind="rformProps.modelRef"
    @submit="onSubmit"
  >
    <a-form-item
      label="真实姓名："
      name="realname"
      v-bind="validateInfos['realname']"
    >
      <a-input type="text" placeholder="姓名" v-model:value="model.realname" />
    </a-form-item>
    <a-form-item
      label="姓名拼音："
      name="pinyin"
      v-bind="validateInfos['pinyin']"
    >
      <a-input type="text" placeholder="拼音" v-model:value="model.pinyin" />
    </a-form-item>
    <a-form-item
      label="登录账号："
      name="username"
      v-bind="validateInfos['username']"
    >
      <a-input type="text" placeholder="账号" v-model:value="model.username" />
    </a-form-item>
    <a-form-item label="登录密码：">
      <a-input
        type="password"
        placeholder="密码不填不改"
        v-model:value="model.password"
      />
    </a-form-item>
    <a-form-item label="手机号：" name="phone" v-bind="validateInfos['phone']">
      <a-input type="text" placeholder="手机号" v-model:value="model.phone" />
    </a-form-item>
    <a-form-item
      label="邮箱(Email)"
      name="email"
      v-bind="validateInfos['email']"
    >
      <a-input
        type="text"
        placeholder="邮箱(Email)"
        v-model:value="model.email"
      />
    </a-form-item>
    <a-form-item
      label="设置角色"
      name="roles_id"
      v-bind="validateInfos['roles_id']"
    >
      <my-roles-select
        placeholder="选择角色"
        :width="300"
        v-model:value="model.roles_id"
      ></my-roles-select>
    </a-form-item>
    <a-form-item label="所属团队：">
      <my-department-select
        placeholder="选择团队"
        v-model:value="model.departments_id"
      ></my-department-select>
    </a-form-item>
    <a-form-item :wrapper-col="formLayout.submitWrapperCol">
      <a-button type="primary" html-type="submit" @click="onSubmit">
        保存
      </a-button>
      <a-button style="margin-left: 10px" @click="$emit('closeModel')">
        关闭
      </a-button>
    </a-form-item>
  </a-form>
</template>
<script>
  import { Form } from 'ant-design-vue'
  const useForm = Form.useForm
  import { reactive, onMounted } from 'vue'
  import { notification } from 'ant-design-vue'
  import { API, requests } from '@/api/apis'
  import MyDepartmentSelect from '@/common/components/Select/MyDepartmentSelect.vue'
  import MyRolesSelect from '@/common/components/Select/MyRolesSelect.vue'
  export default {
    inject: ['formLayout'],
    emits: ['finish'],
    components: { MyDepartmentSelect, MyRolesSelect },
    props: {
      data: {
        type: Object,
      },
    },
    setup(props, context) {
      const modelRef = reactive({
        //表单
        realname: undefined, //真实姓名
        username: undefined, //登录账号
        password: undefined, //密码
        departments_id: undefined, //所属部门
        roles_id: undefined, //所属角色
        pinyin: undefined, //姓名拼音
        phone: undefined, //手机号
        email: undefined, //邮箱
      })
      const rulesRef = reactive({
        //校验
        realname: [
          {
            required: true,
            type: 'string',
            message: '请设置姓名！',
            trigger: 'change',
          },
        ],
        pinyin: [
          {
            required: true,
            type: 'string',
            message: '请输入姓名中文拼音！',
            trigger: 'change',
          },
        ],
        username: [
          {
            required: true,
            type: 'string',
            message: '请设置登录账号！',
            trigger: 'change',
          },
        ],
        roles_id: [
          {
            required: true,
            type: 'array',
            message: '请选择员工角色！',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            type: 'string',
            message: '请输入员工手机号！',
            trigger: 'change',
          },
        ],
        email: [
          {
            required: true,
            type: 'string',
            message: '请输入员工邮箱！',
            trigger: 'change',
          },
        ],
      })

      const { validate, validateInfos } = useForm(modelRef, rulesRef)

      const onSubmit = (e) => {
        //提交
        e.preventDefault()
        validate()
          .then(() => {
            onSubmitForm()
          })
          .catch((err) => {
            console.log('error', err)
          })
      }

      const onSubmitForm = () => {
        requests({
          url: API.admins.Update,
          data: modelRef,
        })
          .then((res) => {
            notification['success']({
              message: '提示',
              description: '编辑成功！',
            })
            context.emit('finish')
          })
          .catch((err) => {
            console.log(err)
          })
      }

      const rformProps = {
        modelRef,
        rulesRef,
      }
      onMounted(() => {
        const data = props.data.value
        data.roles_id = []
        data.roles.forEach((res) => {
          data.roles_id.push(res.id)
        })
        modelRef.id = data.id
        modelRef.realname = data.realname
        modelRef.username = data.username
        modelRef.departments_id = data.departments_id
        modelRef.roles_id = data.roles_id
        modelRef.pinyin = data.pinyin
        modelRef.phone = data.phone
        modelRef.email = data.email
        console.log(modelRef, '修改')
      })
      return {
        rformProps,
        model: modelRef,
        rules: rulesRef,
        validateInfos,
        onSubmit,
      }
    },
  }
</script>

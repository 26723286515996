export default {
  props: {
    value: {
      required: false,
      type: Number,
      default: undefined,
    },
    placeholder: String,
    allowclear: Boolean,
    width: {
      required: false,
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      select: {
        placeholder: '请选择',
        allowclear: false,
      },
      items: [],
    }
  },
  methods: {
    onChange() {
      this.$emit('update:value', this.select.selected)
    },
    setInit() {
      this.select.selected = this.value || undefined
      this.select.placeholder = this.placeholder || '请选择'
      this.select.allowclear = this.allowclear
      console.log(this.select, 'sjj')
    },
  },
  mounted() {
    this.setInit()
    this.onInit()
  },
}

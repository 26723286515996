<template>
  <my-delete-button
    popTitle="是否软删除?"
    icon="DeleteOne"
    title="软删除"
    v-if="!record.deleted_at"
    @confirm="onDeleteChange()"
  />
  <my-edit-button
    v-else
    popTitle="是否恢复?"
    title="恢复"
    type="primary"
    icon="RoundDistortion"
    @click="onRestore()"
  />
</template>
<script>
  import { defineComponent, onMounted, ref } from 'vue'
  import { API, requests } from '@/api/apis'
  export default defineComponent({
    props: {
      record: {
        type: Object,
      },
      api1: {
        type: String,
      },
      api2: {
        type: String,
      },
    },
    methods: {
      onRestore() {
        let _this = this
        requests({
          url: this.api2,
          data: { id: this.record.id },
        })
          .then((res) => {
            _this.$emit('change')
          })
          .catch((err) => {
            console.log(err, '数据')
          })
      },
      onDeleteChange() {
        let _this = this
        requests({
          url: this.api1,
          data: { id: this.record.id },
        })
          .then((res) => {
            _this.$emit('change')
          })
          .catch((err) => {
            console.log(err, '数据')
          })
      },
    },
  })
</script>

<template>
  <a-select
    v-model:value="select.selected"
    @change="onChange"
    mode="multiple"
    allowClear
    :placeholder="select.placeholder"
    :style="`width:${width}px;`"
    :options="items"
  ></a-select>
</template>
<script>
  import SelectMixin from './SelectMixin.js'
  import { API, requests } from '@/api/apis'
  export default {
    mixins: [SelectMixin],
    prpos: {
      value: {
        required: false,
        type: Array,
        default: [],
      },
    },
    data() {
      return {
        select: {
          selected: [],
        },
      }
    },
    watch: {
      value(e) {
        this.select.selected = e
      },
    },
    methods: {
      doRequest() {
        let _this = this
        requests({
          url: API.roles.Select,
        })
          .then((res) => {
            const { data } = res
            _this.items = data
          })
          .catch((err) => {
            console.log(err, '数据')
          })
      },
      onInit() {
        this.doRequest()
      },
    },
  }
</script>

<template>
  <a-select
    v-model:value="selected"
    allowClear
    placeholder="选择"
    style="width: 120px"
    :options="items"
  ></a-select>
</template>
<script>
  import { defineComponent, onMounted, ref } from 'vue'
  import { useVModel } from '@vueuse/core'
  import { API, requests } from '@/api/apis'
  export default defineComponent({
    prpos: {
      value: {
        type: Number,
      },
    },
    setup(props, { emit }) {
      const selected = useVModel(props, 'value', emit)
      let items = ref([])
      const doRequest = () => {
        requests({
          url: API.roles.Select,
        })
          .then((res) => {
            items.value = res.data
          })
          .catch((err) => {
            console.log(err, '数据')
          })
      }
      onMounted(() => {
        doRequest()
      })
      return {
        selected,
        items,
      }
    },
  })
</script>

<template>
  <a-tree-select
    v-model:value="select.selected"
    :placeholder="select.placeholder"
    @change="onChange"
    :style="`width:${width}px;`"
    :replaceFields="{ title: 'name', key: 'id', value: 'id' }"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :tree-data="items"
    allowClear
    tree-default-expand-all
  ></a-tree-select>
</template>
<script>
  import SelectMixin from './SelectMixin.js'
  import { API, requests } from '@/api/apis'
  export default {
    mixins: [SelectMixin],
    data() {
      return {
        select: {
          selected: undefined,
        },
      }
    },
    watch: {
      value(e) {
        this.select.selected = e
        console.log(e, '值')
      },
    },
    methods: {
      doRequest() {
        let _this = this
        requests({
          url: API.departments.List,
        })
          .then((res) => {
            const { data } = res
            _this.items = data
          })
          .catch((err) => {
            console.log(err, '数据')
          })
      },
      onInit() {
        this.doRequest()
      },
    },
  }
</script>
